<template>
  <v-container class="mt-5 ml-2" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="transparent elevation-0">
          <v-card-title primary-title class="text-subtitle-1">
            Reporte semanal por Vendedora
            <v-spacer></v-spacer>
            <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportExcel( modalidades  , 'MODALIDADES_ERP')"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

            <v-btn 
              class="mr-2" 
              small dark color="red"
              @click="downloadZip( vendedoras  , 'repoteszip')"
              tile
            >
              <v-icon small>mdi-folder-zip</v-icon>
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize( ), getRIFecha()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>
          </v-card-title>

          <v-card-text class="black--text">
            <v-row>
              <v-col cols="12" sm="6" lg="4" xl="3">
	              <v-autocomplete
                  filled
                  clearable
                  v-model="vendedora"
                  :items="vendedoras"
                  label="Selecciona una vendedora"
                  item-text="nombre_completo"
                  item-value="id_usuario"
                  dense
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="12" sm="4" lg="3" xl="2">
                <v-text-field
                  label="Seleccione fecha"
                  filled
                  dense
                  clearable
                  v-model="fechaini"
                  type="date"
                ></v-text-field>
              </v-col>
	  		      <v-spacer></v-spacer>
            </v-row>

            <v-row v-if="encabezados">

              <!-- Mostrar el rango de fechas -->
              <v-col cols="12" class="py-0">
                <div class="grey--text"><b>Rango de fechas</b></div>
                <div>
                  <span class="font-weight-bold text-h6">{{ encabezados.fecha_inicio_format }}</span> 
                  <span class="font-weight-bold mx-4">al</span> 
                  <span class="font-weight-bold text-h6">{{ encabezados.fecha_final_format }}</span>
                </div>
              </v-col>

            	<v-col cols="12" md="6" lg="4" xl="3">
                <v-tooltip bottom  color="green">
                  <template v-slot:activator="{ on, attrs }">
                		<v-card class="shadowCard" v-bind="attrs" v-on="on">
                      <v-card-text>
                        <v-row>
                          <v-col cols="9">
                            <div>
                              <h5 class="mb-0 font-weight-bold" >
                                <b>Matriculas nuevas</b>
                              </h5>

                              <p class="mb-0 font-weight-bold text-h6 textPrimary--text">
                                # | {{ encabezados.matriculas_nuevas }} | {{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( encabezados.matriculas_nuevas_monto ) }}
                              </p>
                            </div>
                          </v-col>

                          <v-col cols="2">
                            <v-avatar
                              @click="verDatos( encabezados.nuevasMatriculas)"
                              size="50"
                              rounded
                              class="avatares"
                            >
                              <v-icon color="white">mdi-account-plus</v-icon>
                            </v-avatar>
                          </v-col>
                        </v-row>
                      </v-card-text>
                		</v-card>
                  </template>
                  <span>No existía en sistema y apenas se registró en esta semana.</span>
                </v-tooltip>
            	</v-col>

              <v-col cols="12" md="6" lg="4" xl="3">
                <v-tooltip bottom  color="green">
                  <template v-slot:activator="{ on, attrs }">
                    <v-card class="shadowCard" v-bind="attrs" v-on="on">
                      <v-card-text>
                        <v-row>
                          <v-col cols="9">
                            <div>
                              <h5 class="mb-0 font-weight-bold" >
                                <b>Matriculas liquidadas</b>
                              </h5>

                              <p class="mb-0 font-weight-bold text-h6 textPrimary--text">
                                # | {{ encabezados.matriculas_liquidadas }} | {{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( encabezados.matriculas_liquidadas_monto ) }}
                              </p>
                            </div>
                          </v-col>

                          <v-col cols="2">
                            <v-avatar
                              @click="verDatos( encabezados.matriculas_liquidadas_alumnos)"
                              size="50"     
                              rounded
                              class="avatares"
                            >
                              <v-icon color="white">mdi-account-check</v-icon>
                            </v-avatar>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </template>
                  <span>No existía en sistema y apenas se registró en esta semana y no tienen adeudo.</span>
                </v-tooltip>
              </v-col>

              <v-col cols="12" md="6" lg="4" xl="3">
                <v-tooltip bottom  color="green">
                  <template v-slot:activator="{ on, attrs }">
                    <v-card class="shadowCard" v-bind="attrs" v-on="on">
                      <v-card-text>
                        <v-row>
                          <v-col cols="9">
                            <div>
                              <h5 class="mb-0 font-weight-bold" >
                                <b>Matriculas parciales</b>
                              </h5>

                              <p class="mb-0 font-weight-bold text-h6 textPrimary--text">
                                # | {{ encabezados.matriculas_parciales }} | {{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( encabezados.matriculas_parciales_monto ) }}
                              </p>
                            </div>
                          </v-col>

                          <v-col cols="2">
                            <v-avatar
                              @click="verDatos( encabezados.matriculas_parciales_alumnos )"
                              size="50"
                              rounded
                              class="avatares"
                            >
                              <v-icon color="white">mdi-account-alert</v-icon>
                            </v-avatar>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </template>
                  <span>No existía en sistema y apenas se registró en esta semana pero solo hizo pago parcial.</span>
                </v-tooltip>
              </v-col>

              <v-col cols="12" md="6" lg="4" xl="3">
                <v-tooltip bottom  color="purple">
                  <template v-slot:activator="{ on, attrs }">
                    <v-card class="shadowCard" v-bind="attrs" v-on="on">
                      <v-card-text>
                        <v-row>
                          <v-col cols="9">
                            <div>
                              <h5 class="mb-0 font-weight-bold" >
                                <b>Liq. Mat. Anteriores</b>
                              </h5>

                              <p class="mb-0 font-weight-bold text-h6 textPrimary--text">
                                # | {{ encabezados.matriculas_liquidadas_ant }} | {{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( encabezados.matriculas_liquidadas_ant_monto ) }}
                              </p>
                            </div>
                          </v-col>

                          <v-col cols="2">
                            <v-avatar
                              @click="verDatos( encabezados.matriculas_liquidadas_ant_alumnos )"
                              size="50"
                              rounded
                              class="anteriores"
                            >
                              <v-icon color="white">mdi-account-arrow-left</v-icon>
                            </v-avatar>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </template>
                  <span>Personas que liquidaron esta semana, pero se registraron en otra semana diferente a esta.</span>
                </v-tooltip>
              </v-col>
            </v-row>

            <v-row v-if="encabezados">
              <v-col cols="12">
                <v-card class="shadowCard">
                  <v-card-title primary-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom  color="purple">
                      <template v-slot:activator="{ on, attrs }" >
                        <p class="mb-0 font-weight-bold text-h4 gradient-text" v-bind="attrs" v-on="on">
                          {{ encabezados.prospectosAll }}
                          <span class="ml-2 grey--text">Contactos</span>
                        </p>    
                      </template>
                      <span>Total de contactos que vienen de marketing y sucursal en esta semana.</span>
                    </v-tooltip>
                    <v-spacer></v-spacer>
                  </v-card-title>
                  <v-card-text>
                    <v-row justify="space-between">

                      <!-- SECCIÓN DE MARKETING -->
                      <v-col cols="12" md="6" lg="4">

                        <label class="font-weight-bold text-h6">CONTACTOS MARKETING</label>
                        <br/>

                        <!-- CONTANTOS TOTALES -->
                        <v-tooltip bottom color="purple">
                          <template v-slot:activator="{ on, attrs }" >
                            <div v-bind="attrs" v-on="on">
                              <span class="font-weight-bold text-h5 gradient-text">{{ encabezados.prospectos_mkt }}</span>
                              <span class="ml-2 grey--text text-subtitle-1">Contactos MKT <span class="gradient-text-percent"><b>{{ encabezados.prospectos_mkt_percent }}%</b></span></span>
                              <br/>
                              <v-progress-linear
                                background-color="green lighten-2"
                                color="green darken-1"
                                :value="encabezados.prospectos_mkt_percent"
                              ></v-progress-linear>
                              <br/>

                            </div>
                          </template>
                          <span># y % de contactos provenientes solo de Mareting en esta semana.</span>
                        </v-tooltip>

                        <!-- CONTACTO CERRADOS SIN EXITO -->
                        <v-tooltip bottom color="purple">
                          <template v-slot:activator="{ on, attrs }" >
                            <div v-bind="attrs" v-on="on">
                              <span class="font-weight-bold text-h5 gradient-text">{{ encabezados.prospectosm_cerrados_no_exito }}</span>
                              <span class="ml-2 grey--text text-subtitle-1">Cerrados Sin exito <span class="gradient-text-percent"><b>{{ encabezados.prospectosm_cerrados_no_exito_percent }}%</b></span></span>
                              <br/>
                              <span class="ml-2 grey--text text-subtitle-1"><b>Buenos:</b> | {{ encabezados.prospectosm_cerrados_buenos }} | <b>Malos</b> | {{ encabezados.prospectosm_cerrados_malos }} | <b>Sin Clasif.</b> | {{ encabezados.prospectosm_cerrados_ambos }} |</span>
                              <br/>
                              <v-progress-linear
                                background-color="green lighten-2"
                                color="green darken-1"
                                :value="encabezados.prospectosm_cerrados_no_exito_percent"
                              ></v-progress-linear>
                              <br/>
                            </div>
                          </template>
                          <span># y % de cotactos cerrados sin matricula en esta semana.</span>
                        </v-tooltip>


                        <!-- CONTACTO EN SEGUIMIENTO -->
                        <v-tooltip bottom color="purple">
                          <template v-slot:activator="{ on, attrs }" >
                            <div v-bind="attrs" v-on="on">
                              <span class="font-weight-bold text-h5 gradient-text">{{ encabezados.prospectosm_seguimiento }}</span>
                              <span class="ml-2 grey--text text-subtitle-1">Contactos en seguimiento <span class="gradient-text-percent"><b>{{ encabezados.prospectosm_seguimiento_percent }}%</b></span></span>
                              <br/>
                              <span class="ml-2 grey--text text-subtitle-1"><b>Buenos:</b> | {{ encabezados.prospectosm_seguimiento_buenos }} | <b>Malos</b> | {{ encabezados.prospectosm_seguimiento_malos }} | <b>Sin Clasif.</b> | {{ encabezados.prospectosm_seguimiento_ambos }} |</span>
                              <br/>
                              <v-progress-linear
                                background-color="green lighten-2"
                                color="green darken-1"
                                :value="encabezados.prospectosm_seguimiento_percent"
                              ></v-progress-linear>
                              <br/>
                            </div>
                          </template>
                          <span># y % de cotactos en seguimiento durante esa semana.</span>
                        </v-tooltip>


                        <!-- CONTACTO CERRADOS CON EXITO -->
                        <v-tooltip bottom color="purple">
                          <template v-slot:activator="{ on, attrs }" >
                            <div v-bind="attrs" v-on="on">
                              <span class="font-weight-bold text-h5 gradient-text">{{ encabezados.prospectosm_cerrados_si_exito }}</span>
                              <span class="ml-2 grey--text text-subtitle-1">Inscritos y % de convencimiento <span class="gradient-text-percent"><b>{{ encabezados.prospectosm_cerrados_si_exito_percent }}%</b></span></span>
                              <br/>
                              <v-progress-linear
                                background-color="green lighten-2"
                                color="green darken-1"
                                :value="encabezados.prospectosm_cerrados_si_exito_percent"
                              ></v-progress-linear>
                              <br/>
                            </div>
                          </template>
                          <span># y % de cotactos cerrados con matricula en esta semana.</span>
                        </v-tooltip>
                      </v-col>
                      
                      <v-col cols="12" md="6" lg="4">

                        <label class="font-weight-bold text-h6">CONTACTOS SUCURSAL</label>
                        <br/>

                        <!-- CONTANTOS TOTALES -->
                        <v-tooltip bottom color="purple">
                          <template v-slot:activator="{ on, attrs }" >
                            <div v-bind="attrs" v-on="on">
                              <span class="font-weight-bold text-h5 gradient-text">{{ encabezados.prospectos_vendedora }}</span>
                              <span class="ml-2 grey--text text-subtitle-1">Contactos Sucursal <span class="gradient-text-percent"><b>{{ encabezados.prospectos_vendedora_percent }}%</b></span></span>
                              <br/>
                              <v-progress-linear
                                background-color="green lighten-2"
                                color="green darken-1"
                                :value="encabezados.prospectos_vendedora_percent"
                              ></v-progress-linear>
                              <br/>

                            </div>
                          </template>
                          <span># y % de contactos provenientes solo de Sucursal en esta semana.</span>
                        </v-tooltip>

                        <!-- CONTACTO CERRADOS SIN EXITO -->
                        <v-tooltip bottom color="purple">
                          <template v-slot:activator="{ on, attrs }" >
                            <div v-bind="attrs" v-on="on">
                              <span class="font-weight-bold text-h5 gradient-text">{{ encabezados.prospectosv_cerrados_no_exito }}</span>
                              <span class="ml-2 grey--text text-subtitle-1">Cerrados Sin exito <span class="gradient-text-percent"><b>{{ encabezados.prospectosv_cerrados_no_exito_percent }}%</b></span></span>
                              <br/>
                              <span class="ml-2 grey--text text-subtitle-1"><b>Buenos:</b> | {{ encabezados.prospectosv_cerrados_buenos }} | <b>Malos</b> | {{ encabezados.prospectosv_cerrados_malos }} | <b>Sin Clasif.</b> | {{ encabezados.prospectosv_cerrados_ambos }} |</span>
                              <br/>
                              <v-progress-linear
                                background-color="green lighten-2"
                                color="green darken-1"
                                :value="encabezados.prospectosv_cerrados_no_exito_percent"
                              ></v-progress-linear>
                              <br/>
                            </div>
                          </template>
                          <span># y % de cotactos cerrados sin matricula en esta semana.</span>
                        </v-tooltip>

                        <!-- CONTACTO EN SEGUIMIENTO -->
                        <v-tooltip bottom color="purple">
                          <template v-slot:activator="{ on, attrs }" >
                            <div v-bind="attrs" v-on="on">
                              <span class="font-weight-bold text-h5 gradient-text">{{ encabezados.prospectosv_seguimiento }}</span>
                              <span class="ml-2 grey--text text-subtitle-1">Contactos en seguimiento <span class="gradient-text-percent"><b>{{ encabezados.prospectosv_seguimiento_percent }}%</b></span></span>
                              <br/>
                              <span class="ml-2 grey--text text-subtitle-1">Buenos: | {{ encabezados.prospectosv_seguimiento_buenos }} | Malos | {{ encabezados.prospectosv_seguimiento_malos }} | Sin Clasif. | {{ encabezados.prospectosv_seguimiento_ambos }} |</span>
                              <br/>
                              <v-progress-linear
                                background-color="green lighten-2"
                                color="green darken-1"
                                :value="encabezados.prospectosv_seguimiento_percent"
                              ></v-progress-linear>
                              <br/>
                            </div>
                          </template>
                          <span># y % de cotactos en seguimiento durante esa semana.</span>
                        </v-tooltip>


                        <!-- CONTACTO CERRADOS CON EXITO -->
                        <v-tooltip bottom color="purple">
                          <template v-slot:activator="{ on, attrs }" >
                            <div v-bind="attrs" v-on="on">
                              <span class="font-weight-bold text-h5 gradient-text">{{ encabezados.prospectosv_cerrados_si_exito }}</span>
                              <span class="ml-2 grey--text text-subtitle-1">Inscritos y % de convencimiento <span class="gradient-text-percent"><b>{{ encabezados.prospectosv_cerrados_si_exito_percent }}%</b></span></span>
                              <br/>
                              <v-progress-linear
                                background-color="green lighten-2"
                                color="green darken-1"
                                :value="encabezados.prospectosv_cerrados_si_exito_percent"
                              ></v-progress-linear>
                              <br/>
                            </div>
                          </template>
                          <span># y % de cotactos cerrados con matricula en esta semana.</span>
                        </v-tooltip>

                      </v-col>   
                    </v-row>   

                       <hr style="border-top: 1px dashed #ccc; margin: 20px 0;">

                    <v-row >    
                      <v-col cols="12" md="6" lg="4" >   
                        <label class="font-weight-bold text-h6 purple--text ">REPORTE RI</label>
                        <br/>               
                      </v-col>          
                    </v-row>
                    <v-row >
                       <v-col cols="4" md="4" lg="4" class="text-center">
                        <v-tooltip bottom >
                          <template v-slot:activator="{ on, attrs }">
                            <p class="mb-0 font-weight-bold text-h4" v-bind="attrs" v-on="on">
                              <span class="ml-2 black--text">Reinscribibles:</span>
                              {{ riTotal.totalAlumnosAnteriores }}
                            </p>    
                          </template>
                          <span>Reiscribibles por sucursal y ciclo.</span>
                        </v-tooltip>
                      </v-col>       

                        <v-col cols="4" md="4" lg="4" class="text-center">
                        <v-tooltip bottom >
                          <template v-slot:activator="{ on, attrs }">
                            <p class="mb-0 font-weight-bold text-h4" v-bind="attrs" v-on="on">
                              <span class="ml-2 black--text">Acumulado:</span>
                              {{ riTotal.totalAlumnosSiguientes }}
                            </p>    
                          </template>
                          <span>Acumulado por sucursal y ciclo.</span>
                        </v-tooltip>
                      </v-col>          

                        <v-col cols="4" md="4" lg="4" class="text-center">
                        <v-tooltip bottom >
                          <template v-slot:activator="{ on, attrs }">
                            <p class="mb-0 font-weight-bold text-h4" v-bind="attrs" v-on="on">
                              {{ riTotal.porcentajeAvance }}
                              <span class="ml-2 black--text">%RI</span>
                            </p>    
                          </template>
                          <span>Porcentaje de RI por sucursal y ciclo.</span>
                        </v-tooltip>
                      </v-col>             
                    </v-row>

                    <v-row >    
                      <v-col cols="12">   
                        <label class="font-weight-bold"><b>REPORTE VENTAS SEMANAL:</b></label>
                        <br/>               
                        <h1 class="black--text"><strong>{{ vendedoras.find( el => el.id_usuario == vendedora ).nombre_completo }}</strong></h1>
                      </v-col>          
                    </v-row>

                    <v-row >    
                      <v-col cols="12">   
                        <vue-apex-charts
                          ref="grafica"
                          v-if="verGrafica"
                          type="area"
                          height="350"
                          :options="chartOptions"
                          :series="series"
                        ></vue-apex-charts>
                      </v-col>          
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Alumnos desglose -->
    <v-dialog
      v-model="dialogAlumnos"
      hide-overlay
      persistent
      max-width="800"
    >
      <v-card>
        <v-card-title primary-title >
          Alumnos
        </v-card-title>
        <v-card-text >

          <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="pb-0">
            <v-text-field
              v-model="search"
              dense
              rounded
              filled
              single-line
              label="Buscar"
              append-icon="mdi-magnify"
            ></v-text-field>  
          </v-col>

          <v-data-table
            :headers="headersAlumnos"
            :items="alumnos"
            class="elevation-0"
            dense
            :search="search"
          >
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            color="error" 
            tile 
            dark 
            @click="dialogAlumnos = false"
          >
            <v-icon left small>mdi-cancel</v-icon>
            Cancelar
          </v-btn>

        </v-card-actions>
      </v-card>

    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';

  import { mapGetters } from 'vuex'
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'

  import VueApexCharts from 'vue-apexcharts'

  const formatter = new Intl.NumberFormat('en-US', {
	  style: 'currency',
	  currency: 'USD',
	});

  export default {

    components:{
      Alerta,
      carga,
      VueApexCharts
    },

    mixins: [ validarErrores, funcionesExcel ],

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      fechaini:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      encabezados: null,
      encabezados_ant: null,
      vendedoras: [],
      vendedora: null,
      riTotal: null,
      riTotalpdf: null,

      headersAlumnos:[
        { text: 'Matricula'      , value: 'matricula'  },
        { text: 'Alumno'         , value: 'alumno'     },  
      ],
      alumnos:[],
      search: '',
      dialogAlumnos: false,
      verGrafica: false,
      series: [
        {
          name: "FAST",
          data: [],
        },
        {
          name: "INBI",
          data: [],
        },
      ],

      series2: [
        {
          name: "Venta General",
          data: []
        },
      ],

      chartOptions: {
        chart: {
          height: 500,
          type: "area",
        },

        dataLabels: {
          enabled: true,
        },

        stroke: {
          curve: "smooth",
        },

        xaxis: {
          // type: 'caracter',
          // categories: [],
          categories: [],

          axisBorder: {
            show: false,
          },

          axisTicks: {
            show: false,
          },
          tickPlacement: "on",
          labels: {
            show: true,
            style: {
              fontSize: "12px",
            },
          },
        },
      },

    }),

    computed: {
      ...mapGetters('login', ['getdatosUsuario', 'getLogeado', 'getEscuela','drawer2','getEstatusLlamada','getNuevosProspectos']),
    },

    created () {
      console.log( this.getdatosUsuario )
      this.getVendedoras()
    },

    watch:{
      vendedora( value ){
        if( value && this.fechaini ){ this.initialize(), this.getRIFecha() }
      },

      fechaini( value ){
        if( value && this.vendedora ){ this.initialize(), this.getRIFecha()}
      }
    },

    methods: {
      initialize () {
        this.cargar    = true
        this.ciclos    = []

        if( !this.fechaini || !this.vendedora){ return this.validarErrorDirecto('Favor de llenar los datos correspondientes') }

        this.series = null;

        this.series = [
          {
            name: "INSCRITOS",
            data: [],
          },
        ];

        this.chartOptions.xaxis.categories = [];

        this.verGrafica = false;

        var payload = { 
          fechaini: this.fechaini, 
          vendedora: this.vendedora 
        }

        this.$http.post('reporte.semanal.vendedora', payload ).then(response=>{
        	this.encabezados        = response.data.encabezados
        	this.encabezados_ant    = response.data.encabezados
          this.chartOptions.xaxis.categories = response.data.encabezados.categorias
          this.series[0].data     = response.data.encabezados.dataSeries
          this.verGrafica = true;
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getVendedoras () {
        this.cargar      = true
        this.vendedoras  = []

        this.$http.get('reporte.vendedoras' ).then(response=>{

          if( this.getdatosUsuario.idpuesto == 18 && ![568, 7, 161].includes( this.getdatosUsuario.iderp )  ){
            this.vendedoras   = response.data.filter( el => { return el.id_usuario == this.getdatosUsuario.iderp })
          }else{
            this.vendedoras   = response.data
          }
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },


      exportar(){
        this.exportarData()
      },

      downloadZip( vendedoras ){

        var payload = { 
          fechaini: this.fechaini, 
          vendedoras, 
          infoRI: this.riTotalpdf,
        }

        this.cargar    = true

        this.$http.post('reporte.semanal.vendedora.zip', payload ).then(response=>{
          // this.encabezados        = response.data.encabezados
          // this.encabezados_ant    = response.data.encabezados
          window.open( axios.defaults.baseURL + 'recibo-pago/' + this.fechaini +'.zip')
          this.cargar    = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      },

      getRIFecha() {
        this.cargar = true
        const payload = {
          fechaini: this.fechaini, 
          vendedora: this.vendedora 
        }
        return this.$http.post('kpi.getrifecha', payload ).then(response=>{
          this.riTotal = response.data.riFinal;
          this.riTotalpdf = response.data.riPlantelesTodos;

        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      verDatos( value ){
        console.log( value )
        this.alumnos = value
        this.dialogAlumnos = true
      }
    },
  }
</script>
<style scoped>

  .avatares{
    background-image: linear-gradient(310deg,#17ad37,#98ec2d) !important;
  }


  .anteriores{
    background: rgb(135,45,176);
    background: linear-gradient(149deg, rgba(135,45,176,0.9612219887955182) 0%, rgba(235,44,244,0.9836309523809523) 100%);
  }

  .gradient-text {
    background-image: linear-gradient(149deg, rgba(135,45,176,0.9612219887955182) 0%, rgba(235,44,244,0.9836309523809523) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .gradient-text-percent{
    background-image: linear-gradient(149deg, rgb(8,110,0) 0%, rgba(44,244,57,0.9836309523809523) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

</style>
